<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-7 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row">
                  <div class="col-10 offset-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        <span class="d-none d-md-inline-block">VIEW&nbsp;</span>PLEDGE: {{item.transactionReference}}
                        <div class="row m-0 justify-content-center" >
                          <div class="col-6 col-md-12 text-center pt-md-3 pt-4 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row mx-0 my-4 justify-content-center align-items-center" >
                  <div class="col-12 col-md-auto text-center">
                    <div class="profile_img m-auto">
                      <Image :imageName="item.user.imageUrl" width="50" v-if="item.user.imageUrl" />
                      <IconUser size="sizefull" v-else />
                    </div>
                  </div>
                  <div class="col-12 col-md-auto p-0 text-center text-md-start">
                    <div class="bold green-text font19">
                      <span v-if="item.donationMadeBy">{{item.donationMadeBy.firstName}} {{item.donationMadeBy.lastName}}</span>
                      <span v-else>{{item.user.firstName}} {{item.user.lastName}}</span>
                    </div>
                    <div class="medium green-text font10 letter_spacing">
                      DONOR ID: <span v-if="item.donationMadeBy">{{item.donationMadeBy.userID}}</span><span v-else>{{item.user.userID}}</span>
                    </div>
                  </div>
                </div>
                <div class="row m-0 justify-content-center" >
                  <div class="col-11 px-0 my-3 card_item_inner">
                    <div class="row mx-0 h-100 justify-content-center">
                      <div class="col-12 p-0">
                        <div class="row m-0 justify-content-center">
                          <div class="col-12 p-0 border_top" v-for="(donation, key) in item.donationList" :key="key">
                            <div class="row m-0 align-items-center borders py-2">
                              <!--IMAGE-->
                              <div class="col-12 text-center col-md-auto px-4">
                                 <Image :imageName="projectImage(donation.project.projectID)" width="40" v-if="projectImage(donation.project.projectID)" theClass="item_img" />
                                <!-- <img :src="url + '/static/uploads/images/' + projectImage(donation.project.projectID)"  class="item_img" v-if="projectImage(donation.project.projectID)"> -->
                                <img src="../assets/images/default.png"  class="item_img" v-else>
                              </div>
                              <!--END IMAGE-->
                              <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
                                <div class="row m-0">
                                  <div class="col-md-12 p-0 green-text bold item">
                                    {{donation.project.description}}
                                  </div>
                                  <div class="col-md-12 p-0 font12">
                                    {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}} {{ donation.donationType.description }}
                                  </div>
                                </div>
                              </div>
                              <div class="col col-md-auto text-center text-md-end bold mt-3 mt-md-0 pe-4 item">
                                {{ donation.currency.symbol }} {{ parseFloat(Math.round(donation.amount * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row m-0 justify-content-center" >
                  <!--MOBILE-->
                  <!-- <div class="col-11 px-0 my-3 d-md-none">
                    <div class="row align-items-center">
                      <div class="col-6 px-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="item.paymentOption.method" />
                      </div>
                      <div class="col-6 px-1 text-center green-text">
                        <Button color="grey" width="100" :btnText="date" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-6 px-2 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          PLEDGE TYPE
                        </div>
                      </div>
                      <div class="col-6 px-2 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          PLEDGE DATE
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mt-3">
                      <div class="col-12 text-center">
                        <Button color="green" size="big" width="100" :btnText="displayTotal" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          PLEDGE TOTAL
                        </div>
                      </div>
                    </div>
                  </div> -->
                  <!--END MOBILE-->
                  <!--DESKTOP-->
                  <div class="col-12 px-0 my-3">
                    <div class="row align-items-center">
                      <div class="col-md-4 text-center green-text">
                        <div class="row">
                          <div class="col-12">
                            <Button color="grey" width="100" :btnText="item.paymentOption.method" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing my-2">
                            PLEDGE TYPE
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-center">
                        <div class="row">
                          <div class="col-12">
                            <Button color="green" size="big" width="100" :btnText="displayTotal" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing my-2">
                            PLEDGE TOTAL
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4 text-center green-text">
                        <div class="row">
                          <div class="col-12">
                            <Button color="grey" width="100" :btnText="date" />
                          </div>
                          <div class="col-12 medium green-text font10 letter_spacing  my-2">
                            PLEDGE DATE
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row mt-2">
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          PAYMENT METHOD
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION TOTAL
                        </div>
                      </div>
                      <div class="col-4 text-center">
                        <div class="medium green-text font10 letter_spacing">
                          DONATION DATE
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-11 px-0 my-3 text-center green-text-light font13 bold">
                    This pledge will be updated once your payment has been processed and received. Thereafter a receipt will be issued and this donation will be available in your Donation List under your Donor Profile
                  </div>
                  <!--END DESKTOP-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute w-100 start-0 d-none d-md-block">
          <div class="row my-4 mx-0 justify-content-center w-100">
            <div class="col-6 col-sm-5 col-md-3 col-xl-2 item_nav rounded-pill p-2 text-white bold">
              <div class="row align-items-center py-1 justify-content-between w-100 m-0">
                <div class="col-auto cursor hover" @click="goBack" v-if="theKey > 0">
                  <IconArrowBack />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowBack />
                </div>
                <div class="col p-0 text-center">
                  {{theKey + 1}}/{{totalItems}}
                </div>
                <div class="col-auto cursor hover" @click="goForward" v-if="theKey +  1 < totalItems">
                  <IconArrowForward />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowForward />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconUser: defineAsyncComponent(() => import('../components/icons/IconUser.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Pledges Popup',
  props: ['item', 'theKey', 'totalItems'],
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      url: process.env.VUE_APP_URL_BASE
    }
  },

  computed: {
    ...mapGetters([
      'adminProjects',
      'contentProjects'
    ]),
    date () {
      const date = DateTime.fromMillis(parseInt(this.item.transactionDate))
      return date.toLocaleString(DateTime.DATE_FULL)
    },
    displayTotal () {
      return this.defaultCurrency + ' ' + parseFloat(Math.round(this.item.estimatedValue * 100) / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    }
  },
  methods: {
    goBack () {
      this.$emit('goBack')
    },
    goForward () {
      this.$emit('goForward')
    },
    projectImage (val) {
      const adminProject = this.adminProjects.find(project => project.projectID === val)
      const contentProject = this.contentProjects.find(project => project.adminProjectId === val)
      if (contentProject) {
        const mergedProject = { ...adminProject, ...contentProject }
        if (mergedProject.imageUrl) {
          return mergedProject.imageUrl
        } else {
          return null
        }
      } else {
        return null
      }
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
@media (min-width: 992px) {
  .card_item {
    background-color: #F4F4F4F7;
    border-radius: 22px;
    box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
</style>
